import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Button,
  Image,
  Section,
  Strong,
  Icon,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { MdDone } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | Прокат автомобілів UvixenGood Premier</title>
        <meta
          name={"description"}
          content={"Відчуйте досконалість у кожній подорожі"}
        />
        <meta property={"og:title"} content={"Головна | Прокат автомобілів UvixenGood Premier"} />
        <meta
          property={"og:description"}
          content={"Відчуйте досконалість у кожній подорожі"}
        />
        <meta
          property={"og:image"}
          content={"https://cool.uvixengood.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cool.uvixengood.com/img/favicon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cool.uvixengood.com/img/favicon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="0 0 0 0" quarkly-title="Hero-7">
        <Override
          slot="SectionContent"
          width="100%"
          flex-direction="row"
          max-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            padding="120px 50px 120px 50px"
            lg-width="100%"
            lg-padding="80px 50px 90px 50px"
            sm-padding="80px 25px 90px 25px"
            lg-display="flex"
            lg-flex-direction="column"
          >
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              padding="7px 20px 7px 20px"
              margin="0px 0px 30px 0px"
              border-radius="25px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              sm-flex-direction="column"
              lg-width="fit-content"
            >
              <Text
                margin="0px 10px 0px 0px"
                color="#8b9197"
                font="--base"
                sm-margin="0px 0 5px 0px"
              >
                 Прокат автомобілів
              </Text>
            </Box>
            <Text
              margin="0px 0px 20px 0px"
              font="normal 700 56px/1.2 --fontFamily-sans"
              sm-font="normal 700 42px/1.2 --fontFamily-sans"
            >
              UvixenGood Cars
            </Text>
            <Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
            Тут ваша подорож починається з розкоші. Ми спеціалізуємося на наданні виняткового вибору автомобілів високого класу, щоб задовольнити вишукані смаки та потреби наших вибагливих клієнтів. Незалежно від того, чи плануєте ви ділову поїздку, романтичну подорож, чи просто хочете насолодитися днем задоволення від водіння, ми пропонуємо ідеальне поєднання комфорту, стилю та продуктивності.
            </Text>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              sm-flex-direction="column"
              sm-align-items="stretch"
            >
              <Button
                margin="0px 15px 0px 0px"
                padding="12px 50px 12px 50px"
                background="--color-primary"
                border-radius="8px"
                font="normal 400 17px/1.5 --fontFamily-sans"
                sm-margin="0px 0 15px 0px"
                transition="background-color 0.2s ease 0s"
                border-width="2px"
                border-style="solid"
                border-color="--primary"
                hover-color="--darkL1"
                hover-background="rgba(63, 36, 216, 0)"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
              >
                Контакти
              </Button>
            </Box>
          </Box>
          <Image
            src="https://cool.uvixengood.com/img/1.jpg"
            display="block"
            width="50%"
            object-fit="cover"
            height="100%"
            lg-width="100%"
          />
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://cool.uvixengood.com/img/2.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Про нас
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            UvixenGood виділяється на ринку прокату автомобілів преміум-класу, поєднуючи розкіш з неперевершеним обслуговуванням клієнтів. Наш автопарк включає автомобілі таких брендів, як Audi, Mercedes, BMW, Porsche, Range Rover та інші, що гарантує, що ви будете їздити тільки на найкращому. Кожен автомобіль з нашої колекції ретельно обслуговується та презентується, обіцяючи не тільки найвищий комфорт, але й душевний спокій, який приходить з надійною та стильною їздою.
          </Text>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-8"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          grid-gap="5%"
          sm-min-width="280px"
          lg-flex-wrap="wrap"
          display="flex"
        />
        <Box
          width="60%"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          grid-gap="48px 32px"
          lg-grid-gap="48px"
          lg-width="100%"
          sm-grid-template-columns="1fr"
          md-grid-gap="36px "
        >
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Преміальний вибір</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
               Насолоджуйтесь доступом до бездоганного автопарку розкішних автомобілів, які переосмислюють елегантність та інженерну досконалість.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Індивідуальні враження</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
              Ми віримо, що кожна подорож унікальна. Наша команда прагне налаштувати ваш досвід оренди так, щоб він ідеально відповідав вашому маршруту та вподобанням.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Прозоре ціноутворення</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                На UvixenGood ви отримуєте те, що бачите. Насолоджуйтесь прямими цінами без прихованих платежів, гарантуючи, що ваш розкішний досвід почнеться і завершиться плавно.
              </Text>
            </Box>
          </Box>
          <Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
            <Icon
              display="block"
              category="md"
              icon={MdDone}
              size="20px"
              color="--primary"
              margin="2px 16px 0px 0px"
            />
            <Box display="flex" flex-direction="column">
              <Text
                as="h1"
                margin="0px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
              >
                <Strong>Спеціалізована підтримка</Strong>
              </Text>
              <Text
                as="h1"
                margin="12px 0px 0px 0px"
                font="--base"
                color="--dark"
                width="100%"
                md-width="100%"
                text-align="left"
                lg-margin="8px 0px 0px 0px"
              >
                Наше обслуговування клієнтів таке ж елітне, як і наші автомобілі. Від бронювання до повернення, наша команда тут, щоб забезпечити ваше задоволення та комфорт.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
